import * as XLSX from 'xlsx';

interface ExportCsvArgs {
  formattedData: any[];
  fileName: string;
  fileType: 'csv' | 'xlsx' | 'txt';
}

export const useExportCsv = () => {
  const exportCsv = ({ formattedData, fileName, fileType }: ExportCsvArgs) => {
    const ws = XLSX.utils.json_to_sheet(formattedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws);
    XLSX.writeFile(wb, fileName, { bookType: fileType });
  };

  return { exportCsv };
};
