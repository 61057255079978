import { UserDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useUsers(
  queryParams?: string,
  onSuccess?: (data: UserDTO) => void,
  enabled = true
) {
  const url = `/v2/orm/user?${queryParams}`;
  const request = new Request(url);

  return useAuthenticatedRequest<UserDTO>(request, {
    queryKey: ['users', queryParams],
    enabled: enabled,
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
    onSuccess,
  });
}

export default useUsers;
