import { LocationsDTO } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useLocations(searchParams: string, enabled = true) {
  const url = `/v2/orm/locations?${searchParams}`;
  const request = new Request(url);

  return useAuthenticatedRequest<LocationsDTO>(request, {
    queryKey: ['locations', { searchParams }],
    enabled: enabled,
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
  });
}

export default useLocations;
