import { useCanAddCamera, useExportCsv } from '@hakimo-ui/hakimo/util';
import { Button, HakimoSpinner, InputField } from '@hakimo-ui/shared/ui-base';
import { ArrowDownTrayIcon, FunnelIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { AddCamera } from './add-camera/AddCamera';
import { CamFilterValues } from '@hakimo-ui/hakimo/types';
import { getFormattedExportData, getSearchParams } from './util';
import { useCameras } from '@hakimo-ui/hakimo/data-access';

export interface Props {
  isLoading: boolean;
  isDefault: boolean;
  onClickOpenFilterPanel: () => void;
  onSearch: (value: string) => void;
  camFilters: CamFilterValues;
}

export function CameraListHeader(props: Props) {
  const { isLoading, onClickOpenFilterPanel, isDefault, camFilters } = props;
  const [addCamera, setAddCamera] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const canAddCamera = useCanAddCamera();
  const [searchCameraValue, setSearchCameraValue] = useState('');

  const exportSearchParams = getSearchParams(camFilters);
  const { refetch: fetchExportData } = useCameras(exportSearchParams, false);

  const { exportCsv } = useExportCsv();

  const handleExport = async () => {
    setIsExporting(true);
    try {
      const { data } = await fetchExportData();
      if (!data?.items) {
        throw new Error('No data to export');
      }

      const formattedData = getFormattedExportData(data.items);
      exportCsv({
        formattedData,
        fileName: 'hakimo-cameras-export.csv',
        fileType: 'csv',
      });
    } catch (error) {
      throw new Error('Export Failed');
    } finally {
      setIsExporting(false);
    }
  };

  const onSearchValChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCameraValue(e.target.value);
  };

  return (
    <>
      <div className="flex flex-shrink-0 flex-col gap-4  py-1 pr-4 pl-1">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center gap-4">
            <Button onClick={() => setAddCamera(true)}>Add Camera</Button>
            {isLoading && <HakimoSpinner />}
          </div>
          <div className="">
            <span className="mr-2">
              <Button
                variant="icon"
                onClick={handleExport}
                loading={isExporting}
                title="Download Data"
              >
                <ArrowDownTrayIcon className="w-5" />
              </Button>
            </span>
            <Button
              variant="icon"
              onClick={onClickOpenFilterPanel}
              badge={!isDefault}
            >
              <FunnelIcon className="dark:text-ondark-text-2 h-5 w-5" />
            </Button>
          </div>
        </div>
        <div className=" flex flex-row items-start justify-start gap-4">
          <InputField
            label=""
            onChange={onSearchValChange}
            value={searchCameraValue}
            type="search"
            placeholder="Search camera..."
          />
        </div>
      </div>
      {canAddCamera && addCamera && (
        <AddCamera onClose={() => setAddCamera(false)} />
      )}
    </>
  );
}

export default CameraListHeader;
