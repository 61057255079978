import { SEPARATOR } from '@hakimo-ui/hakimo/data-access';
import { CamFilterValues, Camera } from '@hakimo-ui/hakimo/types';

export function getSearchParams(
  camFilters: CamFilterValues,
  tenantIds?: string[],
  searchedCameraName?: string,
  page?: number,
  pageSize?: number
) {
  const params = new URLSearchParams();
  if (page) {
    params.append('page', String(page));
  }
  if (pageSize) {
    params.append('pageSize', String(pageSize));
  }

  if (camFilters.location.values.length > 0) {
    params.append(
      'locations',
      camFilters.location.values.map((loc) => loc.name).join(SEPARATOR)
    );
  }
  if (searchedCameraName && searchedCameraName.length > 0) {
    params.append('name', searchedCameraName);
  }
  if (tenantIds) {
    params.append('tenants', tenantIds.join(SEPARATOR));
  }
  return params.toString();
}

export function isDefaultFilter(camFilters: CamFilterValues) {
  if (camFilters.location.values.length > 0) {
    return false;
  }
  return true;
}

export function getNextCameraIdInList(cameraId?: string, cameras?: Camera[]) {
  if (!cameraId || !cameras || cameras.length <= 1) {
    return undefined;
  }

  const currentIndex = cameras.findIndex((c) => c.id === cameraId);
  if (currentIndex === -1) {
    return undefined;
  }
  if (currentIndex === cameras.length - 1) {
    return cameras[0].id;
  }
  return cameras[currentIndex + 1].id;
}

export function getFormattedExportData(cameras: Camera[]) {
  return cameras.map((camera) => ({
    name: camera.name,
    location:
      `${camera.location?.name}, ${camera.location?.city}, ${camera.location?.country}` ||
      null,
    isArmed: camera.armStatus.isArmed,
  }));
}
